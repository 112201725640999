import "core-js";

const linkClasses = ["page-stylesheet-link", "loading"];

// Handle only page-related stylesheets (there suppose to be a single such link).
export const isStylesheetLoading = () =>
  document.getElementsByClassName(linkClasses.join(" ")).length > 0;

document.loadStylesheet = (url) => {
  const link = document.createElement("link");

  link.type = "text/css";
  link.rel = "stylesheet";
  link.href = url;

  linkClasses.forEach((cssClass) => link.classList.add(cssClass));

  const onLoad = () => {
    link.classList.remove("loading");
    document.dispatchEvent(new Event("page:stylesheet:loaded"));
    link.removeEventListener("load", onLoad);
  };

  link.addEventListener("load", onLoad);

  document.head.appendChild(link);
};
