import * as Turbolinks from "turbolinks";
import "core-js";

import { isStylesheetLoading } from "./page_loadstylesheet";
import { withErrorTracking } from "lib/appsignal";

const parseCallOnReadyArg = (arg) => {
  if (typeof arg == "function") {
    return [arg];
  } else if (Array.isArray(arg)) {
    return arg;
  } else {
    throw new Error("Invalid argument type given");
  }
};

document.addEventListener("page:javascript:ready", ({ detail }) => {
  withErrorTracking(detail.callback, {
    callbackAddedOn: detail.callbackAddedOn,
    location: window.location.href,
  });
});

document.turbolinksPageLoaded = false;

document.addEventListener("turbolinks:visit", () => {
  document.turbolinksPageLoaded = false;
});

document.addEventListener("turbolinks:load", () => {
  document.turbolinksPageLoaded = true;
});

Turbolinks.start();

document.callOnReady = (arg) => {
  const callbackAddedOn = window.location.href;
  const functions = parseCallOnReadyArg(arg);

  const callback = () => {
    functions.forEach((f) => {
      withErrorTracking(
        () => {
          if (Turbolinks.supported) {
            const onReady = () => {
              document.dispatchEvent(
                new CustomEvent("page:javascript:ready", {
                  detail: {
                    callback: f,
                    callbackAddedOn,
                  },
                }),
              );
            };

            if (document.turbolinksPageLoaded) {
              onReady();
            }

            document.addEventListener("turbolinks:load", onReady);
          } else {
            document.addEventListener("DOMContentLoaded", f);
          }
        },
        {
          location: window.location.href,
        },
      );
    });
  };

  if (isStylesheetLoading()) {
    const callOnStylesheetLoaded = () => {
      callback();

      document.removeEventListener(
        "page:stylesheet:loaded",
        callOnStylesheetLoaded,
      );
    };

    document.addEventListener("page:stylesheet:loaded", callOnStylesheetLoaded);
  } else {
    callback();
  }
};

document.callOnPageChange = (arg) => {
  const functions = parseCallOnReadyArg(arg);

  functions.forEach((f) => {
    if (Turbolinks.supported) {
      document.addEventListener("turbolinks:visit", () =>
        withErrorTracking(f, {
          location: window.location.href,
        }),
      );
    }
  });
};
