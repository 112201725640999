import Appsignal from "@appsignal/javascript";

import * as consolePlugin from "@appsignal/plugin-breadcrumbs-console";
import * as networkPlugin from "@appsignal/plugin-breadcrumbs-network";
import * as pathPlugin from "@appsignal/plugin-path-decorator";

const pushApiKey = (() => {
  const location = window.location.href;

  if (location && location.startsWith("file://")) {
    return null;
  } else {
    return process.env.APPSIGNAL_FRONTEND_PUSH_API_KEY;
  }
})();

const revision = process.env.APP_REVISION;

const baseTags = {
  user_id: document.currentUserId,
  camper_id: document.currentCamperId,
  subdomain: document.currentSubdomain,
};

const errorSource = (error) => {
  if (error.sourceURL && error.line) {
    return `${error.sourceURL}:${error.line}`;
  } else if (error.sourceURL) {
    return error.sourceURL;
  } else {
    return undefined;
  }
};

const sendError = (error, params = {}, tags = {}) => {
  const span = appsignal.createSpan();
  const tagsWithDefaults = {
    ...baseTags,
    ...tags,
    error_source: errorSource(error),
    location: window.location.href,
  };

  span.setAction(errorSource(error));
  span.setError(error);
  span.setParams(params);
  span.setTags(tagsWithDefaults);

  appsignal.send(span);
};

export const appsignal = new Appsignal({
  key: pushApiKey,
  revision: revision,
  ignoreErrors: [
    /ReferenceError: Can't find variable: Intl/,
    /ResizeObserver loop limit exceeded/,
    /https:\/\/connect.podium.com\/widget.js/,
    /Spritzlet.activate is not a function/,
    /Can't find variable: instantSearchSDKJSBridgeClearHighlight/,
    /Object Not Found Matching Id/,
  ],
});

appsignal.use(consolePlugin.plugin());
appsignal.use(networkPlugin.plugin());
appsignal.use(pathPlugin.plugin());

export const trackError = (
  error,
  params = {},
  errorConstructorFallback = Error,
) => {
  if (error instanceof Error) {
    sendError(error, params);
  } else if (error === undefined) {
    sendError(new errorConstructorFallback("No message given"), params);
  } else if (typeof error.message === "string") {
    sendError(new errorConstructorFallback(error.message), params, {
      rawError: error,
    });
  } else if (typeof error === "string") {
    sendError(new errorConstructorFallback(error), params);
  } else {
    sendError(new errorConstructorFallback("Unknown message shape"), params, {
      rawError: error,
    });
  }
};

export const withErrorTracking = (f, tags) =>
  appsignal.wrap(f, {
    ...baseTags,
    ...tags,
  });
